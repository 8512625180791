import React from 'react'
import './styles.css'
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from 'react-router-dom'
import ErrorScreen from './components/ErrorScreen'
import Install from './components/Install'
import Registration from './components/Registration'
import BadUrl from './components/BadUrl'
import NotFound404 from './components/NotFound404'

export default function App() {
  return (
    <Router>
      <Switch>       
        <Route path='/error'>
          <ErrorScreen/>
        </Route>        
        <Route exact path="/">
          <Redirect to="/install" />
        </Route>
        <Route path="/install/:lang?/:panel_id?/:s?/:s1?">
          <Install />
        </Route>
        <Route path="/registration">
          <Registration />
        </Route>
        <Route path="/badurl">
          <BadUrl />
        </Route>
        <Route>
          <NotFound404 />
        </Route>        
      </Switch>
    </Router>

  )
}
