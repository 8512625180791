import React from 'react'
import Header from '../../common/components/Header'
import { translations } from '../../common/utils/constants'
import { useHistory } from 'react-router-dom'
import './notFound404.css'

const NotFound404 = () => {

    
  const history = useHistory()
  
  return <React.Fragment>
    <Header />
    <section>
      <h2 className="title">
        {translations.notfound.page404}
      </h2>
      <h3 className="link">
        <code>
          {history.location.pathname}
        </code>
      </h3>
    </section>
  </React.Fragment>


}

export default NotFound404