import React from 'react'
import {translations} from '../../utils/constants'
import './header.css'

const Header = ({}) => {
    const {header} = translations
    return <div className='header-container'>
        <div className='logo'>            
            {/* <div className='text-1'></div> */}
            {/* <div className='text-2'></div> */}
        </div>
        <div className='tagline'>
            {header.tagline}
        </div>
    </div>
}   

export default Header