import React, { useState, useEffect } from 'react'
//import Input from '../../common/components/Input'
import Button from '../../common/components/Button'
import Header from '../../common/components/Header'
import { translations } from '../../common/utils/constants'
import { getCookie } from '../../common/utils/cookies'
//import { useHistory, useParams } from 'react-router-dom'

import './registration.css'

const Registration = () => {
  
  //const { userName } = useParams()   //"homepage" : "http://192.118.61.100/TenJobs-web" on package.json for relative path
  const [serialNum, setSerialNum] = useState('')
  const [serialNum1, setSerialNum1] = useState('')
  const [language, setLanguage] = useState('he')
  const [panelID, setPanelID] = useState(1)
  const { registration } = translations

  const onSubmit = () => {    

  }

  const years = addItems(1930, new Date().getFullYear(), true)
  const months = addItems(1, 12)
  const days = addItems(1, 31)

  const firstYearOption = defaultOption(registration.year)
  const yearOptions = makeOptions(firstYearOption, years, 'year')
  const firstMonthOption = defaultOption(registration.month)
  const monthsOptions = makeOptions(firstMonthOption, months, 'month')
  const firstDayOption = defaultOption(registration.day)
  const daysOptions = makeOptions(firstDayOption, days, 'day')

  useEffect(() => {
    const lang = getCookie('lang')
    const s = getCookie('s')
    const s1 = getCookie('s1')
    const panel_id = getCookie('panel_id')

    setLanguage(lang)
    setSerialNum(s)
    setSerialNum1(s1)
    setPanelID(panel_id)
  }, [])

  return <React.Fragment>
    <Header />
    <div className='container'>
      <div className='heading'>{registration.heading}</div>
      <div className='subHeading'>{registration.subHeading}</div>
      <div style={{ marginTop: '75px' }} className='input-and-submit'>        
        <div className="controls">
          <label className="label">{registration.gender}</label>
          <div className="radio-container">
            <input type="radio" name="gender" value="M" />{registration.male}
            <input type="radio" name="gender" value="F" style={{marginRight: '20px'}} />{registration.female}
          </div>
          <label id="gender-err" className="alert alert-error" style={{display: 'none'}}>{registration.genderError}</label>
          <label className="label" style={{paddingTop: '20px'}}>{registration.birthday}</label>
          <div className="radio-container">
            <select className="dates select-year" name="dob_year" id="dob_year">
              {yearOptions}
            </select>
            <select className="dates select-month" name="dob_month" id="dob_month">
              {monthsOptions}
            </select>
            <select className="dates" name="dob_day" id="dob_day">
              {daysOptions}
            </select>
          </div>
          <label id="dob-err" className="alert alert-error" style={{display: 'none'}}>{registration.dateError}</label>
          <label className="label" style={{paddingTop: '20px'}}>{registration.place}</label>
          <div className="radio-container" >
            <input type="radio" name="location" value="work" />{registration.work}
            <input type="radio" name="location" value="home" style={{marginRight: '20px'}} />{registration.home}
          </div>
          <label id="location-err" className="alert alert-error" style={{display: 'none'}}>{registration.locationError}</label>
        </div>        
        <div style={{paddingTop: '30px'}}>
          <Button id="ifat-xtn-submit" text={registration.submit} onSubmit={onSubmit} />
        </div>
        <input type="hidden" name="serial_num" id="serial_num" value={serialNum} />
        <input type="hidden" name="serial_num1" id="serial_num1" value={serialNum1} />
        <input type="hidden" name="lang" id="lang" value={language} />
        <input type="hidden" name="panel_id" id="panel_id" value={panelID} />
      </div>

    </div>
  </React.Fragment>


}

const addItems = (min, max, descending) => {
  const arr = []
  if (descending) {
    for (let i = max; i >= min; i--) {
      arr.push(i)
    }
  }
  else {
    for (let i = min; i <= max; i++) {
      arr.push(i)
    }
  }
  return arr
}

const defaultOption = (label) => {
  return <option key={`opt${label}`} value="">{label}</option>
}

const makeOptions = (firstOption, arrOptions, keyLabel) => {
  return [firstOption].concat(arrOptions.map(option => <option key={`${keyLabel}${option}`} value={option}>{option}</option>))
}

export default Registration