import React from 'react'
import { translations } from '../../common/utils/constants'
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../common/components/Header'
import '../Registration/registration.css'

const BadUrl = ({props}) => {

    const location = useLocation();

    const { badurl } = translations
    const message = location.state ? location.state.message || '' : ''
    return <React.Fragment>
        <Header />
        <div className='success-container'>
            <div className='success' style={{border:'3px solid red'}}>
                <i className="fa fa-times cross" aria-hidden="true"></i>
            </div>
            <div className='heading' style={{ marginTop: '48px' }}>{badurl.heading}</div>
            <div className='subHeading' style={{direction: 'rtl'}}>{badurl.message}</div>            
        </div>
    </React.Fragment>


}

export default BadUrl