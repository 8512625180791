import React, { useEffect } from 'react'
import Header from '../../common/components/Header'
import { translations } from '../../common/utils/constants'
import { setCookie /*, getCookie*/ } from '../../common/utils/cookies'
import { /*useHistory,*/ useParams } from 'react-router-dom'
import Addon from '../../common/img/Addon.png'
import Message from '../../common/img/Message.png'
import './install.css'

const Install = () => {

    
  //const history = useHistory()
  //const params = useParams();
  const { lang, panel_id, s, s1 } = useParams()   //"homepage" : "http://192.118.61.100/TenJobs-web" on package.json for relative path
  console.log(lang, panel_id, s, s1)
  const { install } = translations

  useEffect(() => {
    if (lang) {
      setCookie('lang', lang, { expires: 365, sameSite: 'lax' })
    }

    if (panel_id) {
      setCookie('panel_id', panel_id, { expires: 365, sameSite: 'lax' })
    }

    if (s) {
      setCookie('s', s, { expires: 365, sameSite: 'lax' })
    }

    if (s1) {
      setCookie('s1', s1, { expires: 365, sameSite: 'lax' })
    }
  }, [])
  return <React.Fragment>
    <Header />
    <div className='container'>
      <div className='heading'>{install.heading}</div>
      <div className='subHeading' dangerouslySetInnerHTML={{__html: install.subHeading}}></div>
      <div className="install-content">
        <div className="text-container">{install.step1} </div>
        <div className="image-container"><img src={Addon} className="install-img" /></div>
        <div className="text-container morepad">{install.step2} </div>
        <div className="message-container"><img src={Message} /></div>
      </div>

    </div>
  </React.Fragment>


}

export default Install