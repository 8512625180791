export const translations = {
  header:{
    text1:'',
    text2:'',
    tagline:'התקנת תוסף - יפעת בקרת פרסום'
  },
  install: {
    heading: 'התקנת התוסף על דפדפן Google Chrome',
    subHeading: 'לחץ <a href="https://chrome.google.com/webstore/detail/ifat-bakarat-pirsum/hcicdlofgmjjiloeecipffiacpholmkb" target="_blank">כאן</a> להורדת התוסף',
    step1: 'בחלון שנפתח, יש ללחוץ על Add to chrome:',
    step2: 'בהודעה שנפתחת, יש ללחוץ על Add extension:'
  },
  registration:{
    heading:'רישום להתקנת התוסף',
    subHeading:'יש למלא פרטים אישיים',
    gender: 'מין',
    male: 'זכר',
    female: 'נקבה',
    birthday: 'תאריך לידה',
    genderError: 'יש לבחור זכר/נקבה',
    year: 'שנה',
    month: 'חודש',
    day: 'יום',
    dateError: 'יש להזין תאריך לידה חוקי',
    place: 'מהיכן את/ה גולש/ת?',
    work: 'עבודה',
    home: 'בית',
    locationError: 'יש לבחור מיקום',
    submit:'הצטרפות'
  },
  notfound: {
    page404: 'מצטערים, הקישור שגוי'
  },
  error: {
    heading:'משהו השתבש',
    submit:'חזור לדף ההתקנה'
  },
  badurl: {
    heading: 'לינק לא תקין',
    message: 'הלינק שקיבלת לא תקין, אנא פנה למפעיל לקבלת קישור חדש.'
  }
}