import React from 'react'
import { translations } from '../../common/utils/constants'
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../common/components/Header'
import Button from '../../common/components/Button'
import '../Registration/registration.css'

const ErrorScreen = ({props}) => {

    const location = useLocation();
    const history = useHistory()

    const onSubmit = () => {
        history.push('/install')
    }

    const { error } = translations
    const message = location.state ? location.state.message || '' : ''
    return <React.Fragment>
        <Header />
        <div className='success-container'>
            <div className='success' style={{border:'3px solid red'}}>
                <i className="fa fa-times cross" aria-hidden="true"></i>
            </div>
            <div className='heading' style={{ marginTop: '48px' }}>{error.heading}</div>
            <div className='subHeading'>{message}</div>
            <div className='success-button'>
                <Button text={error.submit} onSubmit={onSubmit} />
            </div>
        </div>
    </React.Fragment>


}

export default ErrorScreen